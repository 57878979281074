import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useQueryParam, getSearchParams } from "gatsby-query-params";
import { MasterPage, QuotesPanel } from "../../components";
import SEO from "../../components/seo";
import { clearPivotLocalStorage } from "../../utils";

const Quotes = () => {
  const data = useStaticQuery(graphql`
    query siteData {
      site {
        siteMetadata {
          baseUrl
          agent {
            assets {
              image
            }
            id
            name
            phone
            whatsapp
            email
            website
            title
            imageSrc
            calendly
            bio
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    window.addEventListener("beforeunload", () => clearPivotLocalStorage());

    return window.removeEventListener("beforeunload", () =>
      clearPivotLocalStorage()
    );
  }, []);

  return (
    <MasterPage data={data}>
      <SEO title="Quotes Panel" />
      <QuotesPanel {...getSearchParams()} data={data} />
    </MasterPage>
  );
};

export default Quotes;
